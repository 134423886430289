/* eslint-disable camelcase */
import { IDecodedUser } from "authModule/interfaces/authInterfaces";
import jwtDecode from "jwt-decode";

export const setToken = async (token: string) => {
  localStorage.setItem("accessToken", token);
};

export const getToken = (): string | null => {
  return localStorage.getItem("accessToken");
};

export const getDecodedJwt = (tokn: string = ""): IDecodedUser => {
  try {
    const token = getToken();
    const t = token || tokn;
    const decoded = jwtDecode<IDecodedUser>(t);
    return decoded;
  } catch (e) {
    return {} as IDecodedUser;
  }
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem("refreshToken");
};

export const removeDomainObj = (): void => {
  localStorage.removeItem("domain");
};

export const removeToken = async () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const logOut = async (cb: VoidFunction) => {
  await removeToken();
  cb();
};

export const isAuthenticated = () => {
  try {
    const decodedToken = getDecodedJwt();
    if (decodedToken) {
      const { exp } = decodedToken;
      const currentTime = Date.now() / 1000;
      return exp > currentTime;
    }
    return false;
  } catch (e) {
    return false;
  }
};
