export const BasePaths = {
  ADMIN: "/admin",
  SUPERADMIN: "/super",
  CASH_OFFICER: "/cash-officer",
  AUDITOR: "/auditor",
};

export const AuthPaths = {
  SIGNIN: "/signin",
  FORGOT_PASSWORD: "/forgot-password",
};

export const AdminPaths = {
  ADMIN_DASHBOARD: "/admin/admindashboard",
  GENERAL_DASHBOARD: "/admin/dashboard",
  ACTIVITIES: "/admin/activities",
  PERSONNELS: "/admin/personnels",
  FILES: "/admin/files",
  WORKFLOW: "/admin/workflows",
  WARRANTS: "/admin/warrants",
  EXPENDITURE_CLEARANCE: "/admin/expenditure-clearance",
  CREATE_PERSONNEL: "/admin/personnel/create",
  UPDATE_PERSONNEL: "/admin/personnel/update",
  MDAS: "/admin/mdas",
  AUDITOR_WORKBENCH: "/admin/audits",
  CREATE_MDA: "/admin/mda/create",
  MDA_DETAILS: "/admin/mdas/mda",
  WORKBENCH: "/admin/workbench",
  SETTINGS: "/admin/settings",
  TEMPLATE: "/admin/template",
  BUDGET: "/admin/budget",
  BUDGET_DETAILS: "/admin/budget/details",
  BUDGETS: "/admin/budgets",
  SPECIAL_APPROVAL: "/admin/budgets/special-approval",
  SPECIAL_APPROVAL_UPLOAD: "/admin/budgets/special-approval-upload",
  SPECIAL_APPROVAL_PREVIEW: "/admin/budgets/special-approval-preview",
  LEDGER: "/admin/ledger",
  CONTRACTS_LEDGER: "/admin/contracts",
  VOTE_BOOK: "/admin/votebook",
  PHASE_SEQUENCING: "/admin/settings/expense-management/phase-sequencing",
  WARRANT_REQUEST_PROCESSING: "/admin/phase-sequencing/warrant-request-processing",
  MDA_SECTOR_MAPPING: "/admin/settings/mda-sector-mapping",
  MDA_SETTINGS: "/admin/mdas/details/:id/settings",

  CONTRACT_DETAILS: "/admin/contract/details",

  GAM_ITEM: "/admin/settings/general-admin/item",
  REQUESTS: "/admin/requests",
  EMM_PHASE_SEQUENCING: "/admin/settings/expense-management/phase-sequencing",
  EMM_GENERAL_LEDGER: "/admin/settings/expense-management/general-ledger",
  EMM_DEDUCTIONS: "/admin/settings/expense-management/deductions",
  EMM_TEMPLATES: "/admin/settings/expense-management/templates",
  EMM_EXPENSE_CLASSIFICATION: "/admin/settings/expense-management/expense-classification",
  EMM_YEAR_END_PROCESS: "/admin/settings/expense-management/year-end-process",

  EMM_RECONCILIATION_CLASSIFICATION:
    "/admin/settings/expense-management/reconciliation-classifications",
  EMM_DOCUMENT_CLASSIFICATION: "/admin/settings/expense-management/document-classifications",
  EMM_ITEM: "/admin/settings/expense-management/templates",

  WAM_ITEM: "/admin/settings/workflow-automation/item",

  BMM_MDA_SECTOR_MAPPING: "/admin/settings/budget-management/mda-sector-mapping",
  BMM_MDA_MAPPING: "/admin/settings/budget-management/mda-mapping",

  BMM_ITEM: "/admin/settings/budget-management/item",

  WARRANT_REQUEST_PROCESS_MDA:
    "/admin/settings/expense-management/phase-sequencing/mda-warrant-request-process",
  WARRANT_REQUEST_PROCESS_MINSTRY_OF_FINANCE:
    "/admin/settings/expense-management/phase-sequencing/mof-warrant-request-process",
  PAYMENTS: "/admin/payments",
  PAYMENT_ADVICE: "/admin/payment-advice",
  PAYMENT_LEDGER: "/admin/payment-ledger",
  PAYMENT_REQUEST_DIGITIZATION: "/admin/payments/payment-requests-digitization",
  PAYMENT_REQUESTS: "/admin/payment-requests",
  PAYMENT_TREASURY: "/admin/payment-advice/payment-treasury",
  RECONCILIATION: "/admin/reconciliation",
};

export const SuperAdminPaths = {
  INSTANCES: "/super/instances",
};

export const CashOfficerPaths = {
  PAYMENTS: "/cash-officer/payments",
};
