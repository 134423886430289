export const getInitial = (firstname?: string, lastname?: string): string => {
  return (
    `${firstname?.charAt(0)?.toUpperCase()?.trim()}${lastname?.charAt(0)?.toUpperCase()?.trim()}` ||
    ""
  );
};
export const getFullnameInitial = (fullName?: string): string => {
  const names = fullName ? fullName.split(" ") : [];
  return names.length > 0
    ? `${names[0]?.charAt(0)?.toUpperCase()?.trim()}${names[1]?.charAt(0)?.toUpperCase()?.trim()}`
    : "";
};
