import React, { FC, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import SubMenu from "../../components/SubMenu";
import { INavItem, LOGOUT_NAV } from "constants/sidebarItems";
import { IDrawerProp, ISidebar } from "../../interfaces/submenuInterface";
import Skeleton from "@mui/lab/Skeleton";
import Avatar from "@mui/material/Avatar";
import { ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";

const openedMixin = (theme: Theme, width: number): CSSObject => ({
  width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<IDrawerProp>(({ theme, open, width }) => ({
  width,
  flexShrink: 0,
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminSidebar: FC<ISidebar> = (props: ISidebar) => {
  const [isMobile, setIsMobile] = useState(false);
  const { userDetails } = useAuthenticatedUser();

  return (
    <>
      <Drawer
        variant="permanent"
        open={!props.mobile}
        width={props.width}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 2 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: "100%",
            paddingTop: "5rem",
            background: (theme) =>
              // @ts-ignore
              props.superAdmin ? theme.customColors.bg : theme.palette.tertiary.main,
            boxShadow: "inset 0px -1px 0px #E5E5EA",
          }}
        >
          <Box
            component="div"
            sx={{
              width: "100%",
              maxHeight: "calc(100% - 100px)",
              overflowY: "auto",
            }}
          >
            {props.userRoles ? (
              <List>
                {props.links
                  .filter((v) => !v.bottom)
                  .map((link: INavItem, idx) => (
                    <React.Fragment key={idx}>
                      {link?.roles?.filter((value) => props?.userRoles?.includes(value)) &&
                        link?.roles?.filter((value) => props?.userRoles?.includes(value)).length >
                          0 && (
                          <SubMenu
                            stackMenu={props.stackMenu}
                            mobile={props.mobile}
                            item={link}
                            key={link.url(userDetails)}
                            superAdmin={props.superAdmin}
                          />
                        )}
                    </React.Fragment>
                  ))}
              </List>
            ) : (
              <List>
                {[1, 2, 3, 4, 5].map((v) => (
                  <Box key={v} display="flex" alignItems="center" sx={{ margin: 1, padding: 2 }}>
                    <Skeleton variant="circular" sx={{ bgcolor: "#fff" }}>
                      <Avatar sx={{ width: 20, height: 20 }} />
                    </Skeleton>
                    <Skeleton width="50%" sx={{ bgcolor: "#fff", marginLeft: 4 }}>
                      <Typography>.</Typography>
                    </Skeleton>
                  </Box>
                ))}
              </List>
            )}
          </Box>
          <Box component="div" sx={{ width: "100%" }}>
            {!props.mobile && (
              <Typography
                variant="subtitle2"
                textTransform="uppercase"
                color={(theme) => theme.palette.common.white}
                paddingLeft={4}
              >
                {props.stackMenu ? null : props.superAdmin ? (
                  <Typography color="blue"> Your Account</Typography>
                ) : (
                  <Typography>Your Account</Typography>
                )}
              </Typography>
            )}
            <List>
              <>
                {props.userRoles ? (
                  <>
                    {!props.superAdmin &&
                      props.links
                        .filter((v) => v.bottom)
                        .map((navItem) => (
                          <React.Fragment key={navItem.url(userDetails)}>
                            {navItem?.roles?.filter((value) => props?.userRoles?.includes(value)) &&
                              navItem?.roles?.filter((value) => props?.userRoles?.includes(value))
                                .length > 0 && (
                                <SubMenu
                                  stackMenu={props.stackMenu}
                                  mobile={props.mobile}
                                  item={navItem}
                                  key={navItem.url(userDetails)}
                                  superAdmin={props.superAdmin}
                                />
                              )}
                          </React.Fragment>
                        ))}
                  </>
                ) : (
                  <List>
                    {[1, 2].map((v) => (
                      <Box key={v} display="flex" alignItems="center" px={2} sx={{ margin: 1 }}>
                        <Skeleton variant="circular" sx={{ bgcolor: "#fff" }}>
                          <Avatar sx={{ width: 20, height: 20 }} />
                        </Skeleton>
                        <Skeleton width="50%" sx={{ bgcolor: "#fff", marginLeft: 4 }}>
                          <Typography>.</Typography>
                        </Skeleton>
                      </Box>
                    ))}
                  </List>
                )}
              </>
              <SubMenu
                stackMenu={props.stackMenu}
                mobile={props.mobile}
                item={LOGOUT_NAV}
                superAdmin={props.superAdmin}
              />
            </List>
          </Box>
        </Box>
      </Drawer>
      <Box
        sx={{
          width: "24px",
          height: "24px",
          borderRadius: "100%",
          backgroundColor: "white",
          position: "fixed",
          top: "85px",
          left: props.drawerConfig.isMobile ? "65px" : "220px",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsMobile(!isMobile);
          props.setDrawerConfig({
            isMobile: isMobile,
            drawerWidth: isMobile ? 75 : 232,
          });
        }}
      >
        {props.drawerConfig.isMobile ? (
          <ArrowForwardIos
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "80%",
            }}
          />
        ) : (
          <ArrowBackIosIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "80%",
            }}
          />
        )}
      </Box>
    </>
  );
};

export default AdminSidebar;
