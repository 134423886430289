import { getDecodedJwt, isAuthenticated } from "utils/auth";
import { IDecodedUser } from "authModule/interfaces/authInterfaces";
import { useQuery } from "react-query";
import { fetchPersonnel } from "modules/Admin/services/personnelService";
import handleApiError from "utils/handleApiError";
import { useAlert } from "contexts/NotificationProvider";

export const useAuthenticatedUser = () => {
  const decodedUser: IDecodedUser = getDecodedJwt();
  const { showNotification } = useAlert();
  const userId = decodedUser.user_id;

  const { isLoading, data } = useQuery(["user_by_id", { userId }], fetchPersonnel, {
    enabled: isAuthenticated() && !!userId!,
    onError: (error) => {
      showNotification?.(handleApiError(error), { type: "error" });
    },
  });

  return { isLoading, userDetails: data };
};
