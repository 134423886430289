import { ReactComponent as ActivityIcon } from "assets/svgs/activity.svg";
import { ReactComponent as ContractWhite } from "assets/svgs/contract-white.svg";
import { ReactComponent as DashboardWhite } from "assets/svgs/dashboard-white.svg";
import { ReactComponent as ExpenditureWhite } from "assets/svgs/expenditure-white.svg";
import { ReactComponent as ExpenditureClearanceIcon } from "assets/svgs/expenditureClearance.svg";
import { ReactComponent as GLWhite } from "assets/svgs/gl-white.svg";
import { ReactComponent as LogoutOff } from "assets/svgs/logout-off.svg";
import { ReactComponent as LogoutOn } from "assets/svgs/logout.svg";
import { ReactComponent as MDAWhite } from "assets/svgs/mda-white.svg";
import { ReactComponent as PersonIcon } from "assets/svgs/person.svg";
import { ReactComponent as PersonnelsWhite } from "assets/svgs/personnels-white.svg";
import { ReactComponent as SettingsIcon } from "assets/svgs/settings.svg";
import { ReactComponent as WarrantWhite } from "assets/svgs/warrantIcon.svg";
import { ReactComponent as WorkbenchWhite } from "assets/svgs/workbench-white.svg";
import { ReactComponent as LedgerIcon } from "assets/svgs/workbench.svg";
import { ReactNode } from "react";
import { NavigateFunction } from "react-router-dom";
import { AdminPaths, AuthPaths, SuperAdminPaths } from "routes/paths";
import { logOut } from "utils/auth";
import { Roles } from "../modules/Admin/constants/personnel";

export interface INavItem {
  name: string;
  url: (data?: any) => string;
  icon: (active: boolean) => ReactNode;
  subNav?: INavItem[];
  onClick?: (navi: NavigateFunction) => void;
  bottom?: boolean;
  roles?: string[];
}

export const ADMIN_NAV_ITEMS = [
  {
    name: "Dashboard",
    url: (user: any) =>
      user?.roles.includes(Roles.ADMIN)
        ? AdminPaths.ADMIN_DASHBOARD
        : `${AdminPaths.ADMIN_DASHBOARD}?mda=${user?.mda?.id}`,
    icon: (active: boolean) => <DashboardWhite />,
    roles: [Roles.ADMIN, Roles.MDA_ADMIN],
    bottom: false,
  },
  {
    name: "Dashboard",
    url: () => AdminPaths.GENERAL_DASHBOARD,
    icon: (active: boolean) => <DashboardWhite />,
    roles: [
      Roles.SE_FUND_RELEASE,
      Roles.SE_EXPENDITURE,
      Roles.FUNDS_MANAGER,
      Roles.SE_WARRANT,
      Roles.MDA_ACCOUNTING_OFFICER,
      Roles.MDA_SUPERVISING_OFFICER,
    ],
    bottom: false,
  },
  {
    name: "Activities",
    url: () => AdminPaths.ACTIVITIES,
    icon: (active: boolean) => <ActivityIcon />,
    roles: [Roles.ADMIN],
    bottom: false,
  },
  {
    name: "Workbench",
    url: () => AdminPaths.WORKBENCH,
    icon: (active: boolean) => <WorkbenchWhite />,
    roles: Object.values(Roles)
      .filter((v: string) => v !== Roles.ADMIN)
      .filter((v: string) => v !== Roles.MDA_ADMIN)
      .filter((v: string) => v !== Roles.SUPERADMIN)
      .filter((v: string) => v !== Roles.MDA_INTERNAL_AUDITOR)
      .filter((v: string) => v !== Roles.GLOBAL_AUDITOR)
      .filter((v: string) => v !== Roles.MDA_INTERNAL_AUDITOR)
      .filter((v: string) => v !== Roles.SE_EXPENDITURE)
      .filter((v: string) => v !== Roles.SE_FUND_RELEASE)
      .filter((v: string) => v !== Roles.SE_WARRANT)
      .filter((v: string) => v !== Roles.EMM_ADMIN),
    bottom: false,
  },
  {
    name: "Audit Log",
    url: () => AdminPaths.AUDITOR_WORKBENCH,
    icon: (active: boolean) => <WorkbenchWhite />,
    roles: [Roles.GLOBAL_AUDITOR, Roles.MDA_INTERNAL_AUDITOR],
    bottom: false,
  },
  {
    name: "MDA",
    url: () => AdminPaths.MDAS,
    icon: (active: boolean) => <MDAWhite />,
    roles: [Roles.ADMIN],
    bottom: false,
  },
  {
    name: "MDA",
    url: (user: any) => `${AdminPaths.MDA_DETAILS}/${user?.mda?.id}`,
    icon: (active: boolean) => <MDAWhite />,
    roles: [Roles.MDA_ADMIN],
    bottom: false,
  },
  {
    name: "Personnels",
    url: () => AdminPaths.PERSONNELS,
    icon: (active: boolean) => <PersonnelsWhite />,
    roles: [Roles.ADMIN],
    bottom: false,
  },
  {
    name: "Warrants",
    url: () => AdminPaths.WARRANTS,
    icon: (active: boolean) => <WarrantWhite />,
    roles: Object.values(Roles)
      .filter((v: string) => v !== Roles.SUPERADMIN)
      .filter((v: string) => v !== Roles.BUDGET_ADMIN)
      .filter((v: string) => v !== Roles.EMM_ADMIN)
      .filter((v: string) => v !== Roles.CASH_OFFICER)
      .filter((v: string) => v !== Roles.EXPENDITURE_CLEARANCE_INITIATOR)
      .filter((v: string) => v !== Roles.EXPENDITURE_CLEARANCE_AUTHORISER)
      .filter((v: string) => v !== Roles.EXPENDITURE_REQUEST_REVIEWER)
      .filter((v: string) => v !== Roles.PAYMENT_REQUEST_PROCESSING_INITIATOR)
      .filter((v: string) => v !== Roles.PAYMENT_REQUEST_PROCESSING_REVIEWER)
      .filter((v: string) => v !== Roles.PAYMENT_REQUEST_PROCESSING_AUTHORISER)
      .filter((v: string) => v !== Roles.CONTRACT_PAYMENT_REQUEST_PROCESSING_CHECKER)
      .filter((v: string) => v !== Roles.TREASURY_CASH_OFFICER)
      .filter((v: string) => v !== Roles.MDA_INTERNAL_AUDITOR),
    bottom: false,
  },
  {
    name: "Expenditure Clearance",
    url: () => AdminPaths.EXPENDITURE_CLEARANCE,
    icon: (active: boolean) => <ExpenditureClearanceIcon />,
    roles: Object.values(Roles)
      .filter((v: string) => v !== Roles.SUPERADMIN)
      .filter((v: string) => v !== Roles.BUDGET_ADMIN)
      .filter((v: string) => v !== Roles.GLOBAL_AUDITOR)
      .filter((v: string) => v !== Roles.EMM_ADMIN)
      .filter((v: string) => v !== Roles.MDA_INTERNAL_AUDITOR)
      .filter((v: string) => v !== Roles.WARRANT_AUTHORISER)
      .filter((v: string) => v !== Roles.WARRANT_INITIATOR)
      .filter((v: string) => v !== Roles.WARRANT_REQUEST_APPROVER)
      .filter((v: string) => v !== Roles.WARRANT_REQUEST_REVIEWER)
      .filter((v: string) => v !== Roles.WARRANT_REQUEST_APPROVER)
      .filter((v: string) => v !== Roles.CASH_OFFICER),
    bottom: false,
  },
  {
    name: "Requests",
    url: () => AdminPaths.REQUESTS,
    icon: (active: boolean) => <ExpenditureWhite />,
    roles: Object.values(Roles)
      .filter((v: string) => v !== Roles.SUPERADMIN)
      .filter((v: string) => v !== Roles.BUDGET_ADMIN)
      .filter((v: string) => v !== Roles.CONTRACT_PAYMENT_REQUEST_PROCESSING_CHECKER)
      .filter((v: string) => v !== Roles.GLO_INITIATOR)
      .filter((v: string) => v !== Roles.GLO_REVIEWER)
      .filter((v: string) => v !== Roles.SE_EXPENDITURE)
      .filter((v: string) => v !== Roles.GLOBAL_AUDITOR)
      .filter((v: string) => v !== Roles.SE_FUND_RELEASE)
      .filter((v: string) => v !== Roles.SE_WARRANT)
      .filter((v: string) => v !== Roles.EMM_ADMIN),
    bottom: false,
  },
  {
    name: "Files",
    url: () => AdminPaths.FILES,
    roles: [
      Roles.ADMIN,
      Roles.GLO_INITIATOR,
      Roles.GLO_REVIEWER,
      Roles.FUNDS_MANAGER,
      Roles.EXPENDITURE_REQUEST_REVIEWER,
      Roles.EXPENDITURE_CLEARANCE_INITIATOR,
      Roles.EXPENDITURE_CLEARANCE_AUTHORISER,
      Roles.BUDGET_ADMIN,
      Roles.RECONCILIATION_AUTHORISER,
      Roles.RECONCILIATION_INITIATOR,
    ],
    icon: (active: boolean) => <ExpenditureWhite />,
    bottom: false,
  },
  {
    name: "Vote Book",
    url: () => AdminPaths.VOTE_BOOK,
    icon: (active: boolean) => <WorkbenchWhite />,
    roles: [
      Roles.ADMIN,
      Roles.MDA_ADMIN,
      Roles.MDA_AUTHORISER,
      Roles.MDA_INTERNAL_AUDITOR,
      Roles.MDA_SUPERVISING_OFFICER,
      Roles.MDA_ACCOUNTING_OFFICER,
      Roles.SE_WARRANT,
      Roles.SE_FUND_RELEASE,
      Roles.SE_EXPENDITURE,
      Roles.MDA_INITIATOR,
      Roles.EXPENDITURE_CLEARANCE_AUTHORISER,
      Roles.EXPENDITURE_CLEARANCE_INITIATOR,
      Roles.EXPENDITURE_REQUEST_REVIEWER,
      Roles.WARRANT_REQUEST_REVIEWER,
      Roles.WARRANT_REQUEST_AUTHORISER,
      Roles.WARRANT_AUTHORISER,
      Roles.WARRANT_INITIATOR,
      Roles.DIRECTOR_OF_EXPENDITURE,
      Roles.WARRANT_REQUEST_APPROVER,
    ],
    bottom: false,
  },
  {
    name: "Settings",
    url: () => AdminPaths.SETTINGS,
    icon: (active: boolean, color?: string) => <SettingsIcon />,
    bottom: true,
    onClick: () => {},
    roles: [Roles.ADMIN, Roles.EMM_ADMIN, Roles.BUDGET_ADMIN],
  },
  {
    name: "Budget",
    url: () => AdminPaths.BUDGETS,
    icon: (active: boolean) => <LedgerIcon />,
    roles: [Roles.ADMIN, Roles.BUDGET_ADMIN],
  },
  {
    name: "General Ledger",
    url: () => AdminPaths.LEDGER,
    icon: (active: boolean) => <GLWhite />,
    roles: [
      Roles.GLO_INITIATOR,
      Roles.GLO_REVIEWER,
      Roles.ADMIN,
      Roles.FUNDS_MANAGER,
      Roles.SE_EXPENDITURE,
      Roles.SE_FUND_RELEASE,
    ],
  },
  {
    name: "Contracts",
    url: () => AdminPaths.CONTRACTS_LEDGER,
    icon: (active: boolean) => <ContractWhite />,
    roles: [
      Roles.ADMIN,
      Roles.SE_FUND_RELEASE,
      Roles.SE_EXPENDITURE,
      Roles.SE_WARRANT,
      Roles.PAYMENT_REQUEST_PROCESSING_REVIEWER,
      Roles.PAYMENT_REQUEST_PROCESSING_AUTHORISER,
      Roles.PAYMENT_REQUEST_PROCESSING_INITIATOR,
      Roles.CONTRACT_PAYMENT_REQUEST_PROCESSING_CHECKER,
      Roles.MDA_ACCOUNTING_OFFICER,
      Roles.MDA_SUPERVISING_OFFICER,
      Roles.MDA_AUTHORISER,
      Roles.FUNDS_MANAGER,
      Roles.DIRECTOR_OF_EXPENDITURE,
    ],
  },
  {
    name: "Template",
    url: () => AdminPaths.TEMPLATE,
    icon: (active: boolean) => <MDAWhite />,
  },
  {
    name: "Payments",
    url: () => AdminPaths.PAYMENTS,
    icon: (active: boolean) => <MDAWhite />,
    roles: Object.values(Roles)
      .filter((v: string) => v !== Roles.SUPERADMIN)
      .filter((v: string) => v !== Roles.BUDGET_ADMIN)
      .filter((v: string) => v !== Roles.GLOBAL_AUDITOR)
      .filter((v: string) => v !== Roles.EMM_ADMIN)
      .filter((v: string) => v !== Roles.SE_EXPENDITURE)
      .filter((v: string) => v !== Roles.GLO_INITIATOR)
      .filter((v: string) => v !== Roles.CONTRACT_PAYMENT_REQUEST_REVIEWER),
  },
  {
    name: "Payment Advice",
    url: () => AdminPaths.PAYMENT_ADVICE,
    icon: (active: boolean) => <MDAWhite />,
    roles: [Roles.CASH_OFFICER, Roles.FUNDS_MANAGER, Roles.ADMIN],
  },
  {
    name: "Payment Ledger",
    url: () => AdminPaths.PAYMENT_LEDGER,
    icon: (active: boolean) => <MDAWhite />,
    roles: [
      Roles.CASH_OFFICER,
      Roles.FUNDS_MANAGER,
      Roles.ADMIN,
      Roles.SE_EXPENDITURE,
      Roles.SE_FUND_RELEASE,
    ],
  },
  {
    name: "Reconciliation",
    url: () => AdminPaths.RECONCILIATION,
    icon: (active: boolean) => <MDAWhite />,
    roles: [Roles.RECONCILIATION_AUTHORISER, Roles.RECONCILIATION_INITIATOR, Roles.ADMIN],
  },
];

export const SUPER_ADMIN_NAV_ITEMS = [
  {
    name: "Instances",
    url: () => SuperAdminPaths.INSTANCES,
    icon: (active: boolean) => <PersonIcon />,
    roles: [Roles.SUPERADMIN],
  },
];

export const LOGOUT_NAV = {
  name: "Logout",
  url: () => "",
  icon: (superAdmin: boolean) => (superAdmin ? <LogoutOff /> : <LogoutOn />),
  onClick: (navigate: any) => logOut(() => navigate(AuthPaths.SIGNIN, { replace: true })),
};
