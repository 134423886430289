import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { Close } from "@mui/icons-material";
import { fetchNotifications, setReadAll } from "./NotificationServices";
import { useQuery, useQueryClient } from "react-query";
import Empty from "shared/components/Empty";
import { ReactComponent as CompletedIcon } from "../../../assets/svgs/processCompleted.svg";

const NotificationDrawer = ({ open, close }: any) => {
  const queryClient = useQueryClient();

  const { data: dataItem } = useQuery(["fetching-user-notification"], fetchNotifications);

  const { refetch } = useQuery(["setting-read-all"], setReadAll, {
    enabled: false,
    onSuccess: () => {
      queryClient.refetchQueries(`fetching-user-notification`);
    },
  });

  const onReadAll = () => {
    refetch();
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={close} sx={{ width: "400px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "400px" }}>
        <Wrapper>
          <Title>
            <Typography variant="subtitle1" color="text.title">
              Notifications
            </Typography>
          </Title>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Wrapper>
        <Container>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Typography variant="caption" color="text.secondary">
              LATEST
            </Typography>
            <Button
              variant="text"
              sx={{ tSize: "10px", color: "#272833", cursor: "pointer" }}
              onClick={onReadAll}
            >
              Mark all as read
            </Button>
          </Box>
          {dataItem?.results.length > 0 ? (
            dataItem?.results?.map(({ message, read, created_at }: any) => (
              <Wrap key={message} sx={{ backgroundColor: read ? "#FFFFFF" : "#f0f5ff" }}>
                <Box sx={{ m: 2 }}>
                  <CompletedIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 2,
                  }}
                >
                  <Typography variant="caption" sx={{ marginTop: "5px" }}>
                    <span style={spanStyle}>{message}</span>
                  </Typography>
                  <Typography variant="caption" sx={{ color: "#605E5C" }}>
                    {created_at ? format(new Date(created_at), "dd MMMM, yyyy") : "--"}
                  </Typography>
                </Box>
              </Wrap>
            ))
          ) : (
            <>
              <Box mt={64}>
                <Empty title="No Notification" description="You have no notification." />
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
const Container = styled(Box)({
  paddingLeft: "15px",
  paddingRight: "15px",
  display: "flex",
  flexDirection: "column",
});

const Wrapper = styled(Box)({
  height: "40px",
  display: "flex",
  marginBottom: 4,
});

const Title = styled(Box)({
  height: "100%",
  width: "89%",
  backgroundColor: "#F1F2F6",
  display: "flex",
  alignItems: "center",
  paddingLeft: "15px",
});

const spanStyle = {
  color: "#272833",
  fontWeight: "600",
};

const Wrap = styled(Box)({
  display: "flex",
  marginTop: "10px",
  marginBottom: "10px",
  paddingRight: "10px",
});
