import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { BehaviorSubject } from "rxjs";
import { getRefreshToken, getToken, isAuthenticated, removeToken, setToken } from "./auth";

let refreshed = false;

export const baseUrl = process.env.REACT_APP_API_BASE_URL || "https://api.py.prowoks.co/api/v1";
export const workbenchSocketUrl =
  process.env.REACT_APP_WORKBENCH_SOCKET_URL ||
  "wss://api.py.prowoks.co/ws/workbench-notification/";
export const subscriber = new BehaviorSubject(0);
const Api = axios.create({
  baseURL: baseUrl,
});

// Request interceptor for API calls
Api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.headers = {
      Authorization: isAuthenticated() ? `Bearer ${getToken()}` : "",
    };
    return config;
  },
  (err) => {
    if (
      err?.response?.status === 401 &&
      // @ts-ignore
      err?.response?.data?.detail === "Incorrect authentication credentials." &&
      !refreshed
    ) {
      refreshed = true;
      Api.post("/auth/token/refresh/", {
        refresh: getRefreshToken(),
      })
        .then((res: any) => {
          setToken(res.data.access);
          Api(err.config);
        })
        .catch((error) => {
          toast.error(error?.message || "Session expired", {
            position: "top-center",
          });
          setTimeout(() => {
            removeToken();
            window.history.pushState({}, "User Login", "/login");
          }, 4000);
          return error;
        });
      return;
    }
    refreshed = false;
    // Promise.reject(err);
    return err;
  }
);

// Response interceptor for API calls
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // const originalRequest = error?.config;
    // if (error?.response?.status === 401 && !originalRequest?._retry) {
    //   originalRequest._retry = true;
    //   const rToken = getRefreshToken();
    //   if (rToken) {
    //     const access_token = await refreshToken(rToken);
    //     axios.defaults.headers.common["Authorization"] =
    //       "Bearer " + access_token;
    //   }
    //   return Api(originalRequest);
    // }
    return Promise.reject(error);
  }
);

// function refreshToken(token: string) {
//   return Api.post("/auth/token/refresh/", {
//     refresh: token,
//   });
// }

export const Post = async (url: string, data: any, config?: any, newUrl?: string) => {
  try {
    return await axios.post(`${newUrl ? newUrl : baseUrl}${url}`, data, {
      responseType: "blob",
      headers: {
        Authorization: isAuthenticated() ? `Bearer ${getToken()}` : "",
        ...config,
      },
    });
  } catch (error: any) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export default Api;
