import React, { ReactNode } from "react";
import { useQuery } from "react-query";
import { Theme, ThemeProvider } from "@mui/material";
import { fetchAppInstance } from "services/appService";
import { createTheme, darken, lighten } from "@mui/material/styles";
import Loader from "shared/components/Loader";

interface IAppInstanceProps {
  children: ReactNode;
  theme: Theme;
}

function getFaviconEl() {
  return document.getElementById("favicon") as HTMLAnchorElement | null;
}

function getAppleTouchIcon() {
  return document.getElementById("appleTouchIcon") as HTMLAnchorElement | null;
}

function getappTitle() {
  return document.getElementById("appTitle");
}

const AppInstanceContext = React.createContext<{}>({});

const AppInstanceProvider = ({ children, theme }: IAppInstanceProps) => {
  const instanceSlug = process.env.REACT_APP_INSTANCE_SLUG || "";

  const { data, isLoading } = useQuery(["appInstance", { instanceSlug }], fetchAppInstance, {
    enabled: !!instanceSlug,
    onSuccess: (data) => {
      const favicon: HTMLAnchorElement | null = getFaviconEl(); // Accessing favicon element
      const appTouch: HTMLAnchorElement | null = getAppleTouchIcon();
      const appTitle: HTMLElement | null = getappTitle();
      if (favicon) {
        favicon.href = data?.data?.logo;
      }
      if (appTouch) {
        appTouch.href = data?.data?.logo;
      }
      if (appTitle) {
        appTitle.innerHTML = `${data?.data?.name} EMS`;
      }
    },
  });

  const instanceTheme = React.useMemo(() => {
    if (data) {
      return createTheme({
        ...theme,
        palette: {
          primary: {
            main: data?.data?.primary_color,
            light: lighten(data?.data?.primary_color, 0.4),
            dark: darken(data?.data?.primary_color, 0.3),
          },
          secondary: {
            main: data?.data?.secondary_color,
            light: lighten(data?.data?.secondary_color, 0.9),
            dark: darken(data?.data?.secondary_color, 0.9),
          },
          // @ts-ignore
          tertiary: {
            main: data?.data?.tertiary_color,
          },
          // @ts-ignore
          background: {
            ...theme.palette.background,
            // @ts-ignore
            avatar: lighten(data?.data?.primary_color, 0.4),
          },
        },
        components: {
          ...theme.components,
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 4,
                textTransform: "capitalize",
              },
              outlinedPrimary: {
                border: "0px",
                color: data?.data?.primary_color,
                background: lighten(data?.data?.primary_color, 0.9),
                "&:hover": {
                  border: "none",
                  background: lighten(data?.data?.primary_color, 0.7),
                },
              },
              outlinedSecondary: {
                border: "0px",
                color: data?.data?.secondary_color,
                background: lighten(data?.data?.secondary_color, 0.9),
                "&:hover": {
                  border: "none",
                  background: lighten(data?.data?.secondary_color, 0.7),
                },
              },
            },
          },
        },
      });
    }
    return theme;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) <Loader />;
  return (
    <AppInstanceContext.Provider value={{ ...data?.data }}>
      <ThemeProvider theme={instanceTheme}>{children}</ThemeProvider>
    </AppInstanceContext.Provider>
  );
};

export const useAppInstance: any = () => {
  return React.useContext(AppInstanceContext);
};

export default AppInstanceProvider;
