import { IDecodedUser } from "authModule/interfaces/authInterfaces";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type stepConfigType = {
  label: number | string;
  status: string; // options => "current" | "done" | "awaiting";
  icon?: any;
  bgColor?: string;
};

export type drawerConfigSettings = {
  isMobile?: boolean | null;
  drawerWidth?: number | null;
};
export interface ILayoutContext {
  setShowSidebar?: Dispatch<SetStateAction<boolean>>;
  setIsChangeLayoutBg?: Dispatch<SetStateAction<boolean>>;
  setRemovePadding?: Dispatch<SetStateAction<boolean>>;
  setDrawerConfig?: Dispatch<SetStateAction<drawerConfigSettings>>;
  user: IDecodedUser | null;
  setSideStepsConfig?: Dispatch<SetStateAction<Partial<stepConfigType[]> | undefined>>;
  openProgress: boolean;
  setOpenProgress: (e: boolean) => void;
  drawerWidth: number;
}
// @ts-ignore
export const LayoutContext = createContext<ILayoutContext>({ user: null });

export const useLayoutState = () => {
  const {
    setShowSidebar,
    setIsChangeLayoutBg,
    user,
    setRemovePadding,
    setSideStepsConfig,
    setDrawerConfig,
    openProgress,
    setOpenProgress,
    drawerWidth,
  } = useContext(LayoutContext);
  return {
    setShowSidebar,
    setIsChangeLayoutBg,
    user,
    setRemovePadding,
    setSideStepsConfig,
    setDrawerConfig,
    openProgress,
    setOpenProgress,
    drawerWidth,
  };
};
